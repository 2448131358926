import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'

const useSocialStore = defineStore('social', {
  state: () => {
    return {
      social: [
        {
          image: 'linkedin.svg',
          url: 'https://www.linkedin.com/in/brunomiguelbarrospinto',
          alt: 'LinkedIn'
        },
        {
          image: 'gmail.svg',
          url: 'mailto:brunomiguelbarrospinto@gmail.com',
          alt: 'Gmail'
        },
        {
          image: 'youtube.svg',
          url: 'https://www.youtube.com/@brunomiguelbarrospinto',
          alt: 'YouTube'
        },
        {
          image: 'instagram.svg',
          url: 'https://www.instagram.com/brunomiguelbarrospinto',
          alt: 'Instagram'
        }
      ]
    }
  },

  getters: {
    getSocial: (state) => {
      return state.social
    }
  }
})

export default useSocialStore
