<template>
  <!-- <div>
    <ButtonComponent
      class="bg-teal-500! text-white!"
      rightIcon="ArrowDownTrayIcon"
      :text="'Descargar CV'"
      @click="generatePDF"
      size="sm"
      block
    />
  </div> -->
</template>

<script setup lang="ts">
import { jsPDF } from 'jspdf'
import { font } from './PixelifySans-Regular-normal'
import useImageAsBase64 from '@/composables/useImageAsBase64'
import AvatarCircle from '@/assets/img/avatar-circle.png'
import FilminCircle from '/img/experiences/filmin-circle.png'
import PassnflyCircle from '/img/experiences/passnfly-circle.png'
import BusupCircle from '/img/experiences/busup-circle.png'
import PasionaCircle from '/img/experiences/pasiona-circle.png'
import DeliverumCircle from '/img/experiences/deliverum-circle.png'
import { storeToRefs } from 'pinia'
import useKnowledgeStore from '@/stores/knowledge'
import ButtonComponent from '@/components/common/ButtonComponent.vue'

const knowledgeStore = storeToRefs(useKnowledgeStore())

const knowledge = knowledgeStore.getKnowledge.value

const { base64: avatar, load: loadAvatar } = useImageAsBase64({ url: AvatarCircle })
loadAvatar()

const { base64: filmin, load: loadFilmin } = useImageAsBase64({ url: FilminCircle })
loadFilmin()

const { base64: passnfly, load: loadPassnfly } = useImageAsBase64({ url: PassnflyCircle })
loadPassnfly()

const { base64: Busup, load: loadBusup } = useImageAsBase64({ url: BusupCircle })
loadBusup()

const { base64: Pasiona, load: loadPasiona } = useImageAsBase64({ url: PasionaCircle })
loadPasiona()

const { base64: Deliverum, load: loadDeliverum } = useImageAsBase64({ url: DeliverumCircle })
loadDeliverum()

function generatePDF() {
  const doc = new jsPDF('p', 'mm', 'a4')

  // doc.addFileToVFS('PixelifySans-Regular-normal.ttf', font)
  // doc.addFont('PixelifySans-Regular-normal.ttf', 'PixelifySans-Regular-normal', 'normal')
  // doc.setFont('PixelifySans-Regular-normal')

  // Avatar, nombre, profesion, ubicacion
  doc.addImage(avatar.value, 'png', 10, 10, 21, 21)
  doc.setFontSize(20)
  doc.text('Bruno Miguel Barros Pinto', 37, 15)
  doc.setFontSize(16)
  doc.text('Senior Frontend Developer', 37, 23)
  doc.setFontSize(13)
  doc.setTextColor('#6b7280')
  doc.text('Barcelona, Cataluña, España', 37, 30)

  // Acerca de
  doc.setFontSize(16)
  doc.setTextColor('#000000')
  doc.text('Acerca de mi!', 10, 45)
  doc.setFontSize(12)
  doc.setTextColor('#000000')
  var strArr = doc.splitTextToSize(
    'Hola! Soy Bruno Barros. Llevo desarrollando web desde hace 7 años  aproximadamente. Actualmente especializándome en tecnologías relacionadas con el mundo "Frontend" como Javascript, VueJS, TypeScript, Firebase.',
    195
  )
  doc.text(strArr, 10, 53)

  // Experiencia
  doc.setFontSize(16)
  doc.setTextColor('#000000')
  doc.text('Experiencia', 10, 75)

  // Filmin
  doc.addImage(filmin.value, 'png', 10, 82, 11, 11)
  doc.setFontSize(14)
  doc.setTextColor('#000000')
  doc.text('Senior Frontend Developer', 24, 85)
  doc.setFontSize(12)
  doc.setTextColor('#6b7280')
  doc.text('Filmin - Barcelona, Cataluña España', 24, 92)
  doc.text('Jul 2020 -', 155, 85)
  doc.setTextColor('#14b8a6')
  doc.text('Presente', 177, 85)
  doc.setTextColor('#6b7280')
  doc.text('4 años 6 meses', 166, 92)

  // Passnfly
  doc.addImage(passnfly.value, 'png', 10, 100, 11, 11)
  doc.setFontSize(14)
  doc.setTextColor('#000000')
  doc.text('Frontend Developer', 24, 103)
  doc.setFontSize(12)
  doc.setTextColor('#6b7280')
  doc.text('Passnfly - Barcelona, Cataluña España', 24, 110)
  doc.text('Jul 2019 - Jul 2020', 162, 103)
  doc.setTextColor('#6b7280')
  doc.text('1 año', 187, 110)

  // Busup
  doc.addImage(Busup.value, 'png', 10, 118, 11, 11)
  doc.setFontSize(14)
  doc.setTextColor('#000000')
  doc.text('Web Developer', 24, 121)
  doc.setFontSize(12)
  doc.setTextColor('#6b7280')
  doc.text('Busup - Barcelona, Cataluña España', 24, 128)
  doc.text('Mar 2018 - Jul 2019', 160, 121)
  doc.setTextColor('#6b7280')
  doc.text('1 año 4 meses', 170, 128)

  // Pasiona
  doc.addImage(Pasiona.value, 'png', 10, 136, 11, 11)
  doc.setFontSize(14)
  doc.setTextColor('#000000')
  doc.text('Web Developer', 24, 139)
  doc.setFontSize(12)
  doc.setTextColor('#6b7280')
  doc.text('Pasiona - Barcelona, Cataluña España', 24, 146)
  doc.text('Sep 2017 - Mar 2018', 159, 139)
  doc.setTextColor('#6b7280')
  doc.text('6 meses', 182, 146)

  // Deliverum
  doc.addImage(Deliverum.value, 'png', 10, 154, 11, 11)
  doc.setFontSize(14)
  doc.setTextColor('#000000')
  doc.text('Junior Web Developer', 24, 157)
  doc.setFontSize(12)
  doc.setTextColor('#6b7280')
  doc.text('Deliverum - Barcelona, Cataluña España', 24, 164)
  doc.text('Oct 2016 - Ago 2017', 159, 157)
  doc.setTextColor('#6b7280')
  doc.text('9 meses', 182, 164)

  // Conocimientos
  doc.setFontSize(16)
  doc.setTextColor('#000000')
  doc.text('Conocimientos', 10, 180)

  const columns = 4
  const rowHeight = 9 // Increased row height to add more space between rows
  const columnWidth = 53
  const startX = 12
  const startY = 191
  const padding = 1 // Define padding for left and right

  knowledge.forEach((k, i) => {
    const col = i % columns
    const row = Math.floor(i / columns)
    const xPosition = startX + col * columnWidth
    const yPosition = startY + row * rowHeight

    const textWidth = doc.getTextWidth(k.name) + padding * 2 // Get the width of the text and add padding

    doc.setFillColor(k.background) // Set the background color
    doc.rect(xPosition - padding, yPosition - 5, textWidth, rowHeight - 2, 'F') // Draw the rectangle with text width and padding

    doc.setFontSize(12)
    doc.setTextColor('#ffffff')
    doc.text(k.name, xPosition, yPosition)
  })

  // make a border in all pade of the document with color red and 3px
  doc.setDrawColor(20, 184, 166)
  doc.setLineWidth(2)
  doc.rect(0, 0, 210, 297)

  doc.save('BrunoMiguelBarrosPinto.pdf')
}
</script>

<style scoped></style>
