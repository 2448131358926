<template>
  <div
    class="mt-16 sm:mt-20 w-full inline-flex flex-nowrap [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] overflow-x-hidden overflow-y-hidden"
    x-data="{}"
    x-init="$nextTick(() => {
                        let ul = $refs.logos;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
  >
    <ul
      x-ref="logos"
      class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
    >
      <li
        class="relative aspect-9/10 w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 sm:w-72 sm:rounded-2xl"
        v-for="(n, key) in 12"
        :key="'galery-image-' + key"
        :class="{
          'rotate-2': key % 2 !== 0,
          '-rotate-2': key % 2 === 0
        }"
      >
        <img
          alt=""
          rel="preload"
          loading="lazy"
          decoding="async"
          data-nimg="1"
          fetchpriority="high"
          class="absolute inset-0 h-full w-full object-cover"
          style="color: transparent"
          sizes="(min-width: 640px) 18rem, 11rem"
          :src="'/img/galery/' + n + '.jpg'"
        />
      </li>
    </ul>
  </div>
</template>
